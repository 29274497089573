import React, { Component } from "react";

export class ELogo extends Component {
  render() {
    return (
      <div className="card " style={{ marginTop: 10, marginBottom: 0 }}>
        <h6 className="text-center" style={{ marginTop: 20 }}>
          نماد اعتماد الکترونیکی
        </h6>
        <div className="text-center enamad " style={{ marginTop: 0 }}>
          <img src="/images/e.png" alt="" id="vORmIO0dlasFALFD" />
        </div>
      </div>
    );
  }
}
